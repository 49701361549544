@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure the video covers the entire background */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0; /* Ensure it stays behind other content */
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.section {
  position: relative;
  min-height: 100vh; /* Ensure each section takes up the full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem; /* Adjust as needed */
}

.sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 50; /* Ensure it stays above other content */
}

.carousel-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 500px; /* Set a fixed height for all images */
  object-fit: cover; /* Ensure the image covers the container without stretching */
}

.carousel-indicators {
  position: absolute;
  bottom: -30px; /* Position the indicators outside the carousel content */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.custom-font {
  display: inline-flex;
  align-items: center;
  padding-top: 1.5rem; /* py-6 */
  padding-bottom: 1.5rem; /* py-6 */
  padding-left: 0.25rem; /* px-1 */
  padding-right: 0.25rem; /* px-1 */
  margin-right: 0.5rem; /* mr-2 */
  color: #fefefe; /* text-red-100 */
  font-size: 2.25rem; /* text-4xl */
  font-weight: bold; /* font-bold */
  font-family: "Indie Flower", cursive; /* Indie Flower font */
  transition: color 0.3s ease;
}

.custom-font:hover {
  color: #065f46; /* hover:text-green-800 */
}

@media (min-width: 768px) {
  .custom-font {
    padding-left: 0.5rem; /* md:px-2 */
    padding-right: 0.5rem; /* md:px-2 */
    margin-right: 1.5rem; /* md:mr-6 */
  }
}

.custom-nav-link {
  font-size: 1.5rem; /* md:text-4xl */
  font-weight: bold; /* font-bold */
  font-family: "Indie Flower", cursive; /* Indie Flower font */
  transition: color 0.3s ease;
}

.custom-nav-link:hover {
  color: #065f46; /* hover:text-green-800 */
}

/* Ensure the CSS is loaded as early as possible */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black; /* Set a background color to prevent white flash */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: black; /* Maintain background color during loading */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black; /* Maintain background color during loading */
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
